<template>
  <div class="container">
    <header>
      <div @click="show = true" class="left">
        <img src="@/assets/new/lists.png" alt="" />
        <span>{{ showProductName() }}/USDT</span>
        <span
          class="range"
          :style="{
            color:
              productNowInfo[activeIndex].BasicPrice -
                productNowInfo[activeIndex].Price >
              0
                ? basicConfig.FallColour
                : basicConfig.RiseColour
          }"
        >
          {{ getRole() }}%
        </span>
      </div>
      <div class="right">
        <img
          src="@/assets/new/contract.png"
          alt=""
          v-if="productNowInfo[activeIndex].IsContract"
          @click="goContract"
        />
        <img
          src="@/assets/new/gang1.png"
          alt=""
          v-if="productNowInfo[activeIndex].IsSecondContract"
          @click="goProduct"
        />
        <img
          src="@/assets/new/datamap.png"
          alt=""
          @click="
            $router.push({
              name: 'KLine',
              query: {
                Id: productNowInfo[activeIndex].Id
              }
            })
          "
        />
      </div>
    </header>

    <div class="top">
      <div class="order-box">
        <div class="buy">
          <div
            class="btn"
            @click="mode = 0"
            :style="{
              background: mode === 0 ? basicConfig.RiseColour : null,
              color: mode === 0 ? '#fff' : null
            }"
          >
            {{ $t('trade').Buy }}
          </div>
          <div class="line"></div>
          <div
            class="btn"
            @click="mode = 1"
            :style="{
              background: mode === 1 ? basicConfig.FallColour : null,
              color: mode === 1 ? '#fff' : null
            }"
          >
            {{ $t('trade').Sell }}
          </div>
        </div>
        <div class="buy-mode" @click="showActions = true">
          {{ actions[activeSelect] }} <img src="@/assets/new/pull.png" alt="" />
        </div>
        <div class="box">
          <div class="input" v-if="activeSelect === 0">
            <input
              v-model="form.Price"
              :placeholder="$t('trade').jiage"
              type="number"
            />
            <div class="control">
              <img
                class="del"
                src="@/assets/new/sub.png"
                @click="handleDel"
                alt=""
                srcset=""
              />
              <img
                src="@/assets/new/plus.png"
                alt=""
                srcset=""
                @click="handleAdd"
              />
            </div>
          </div>
          <div class="input mark" v-else>{{ $t('trade').zyjg }}</div>
          <div class="input">
            <input
              v-model="form.Num"
              type="number"
              :placeholder="$t('trade').shuliang"
            />
            <span>{{ productNowInfo[activeIndex].Name }}</span>
          </div>
          <div class="validate" v-if="mode === 0">
            {{ $t('trade').keyong }}：{{ LegalAmountInfo.USDT }} USDT
          </div>
          <div class="validate" v-else>
            {{ $t('trade').keyong }}：{{ getCoinBalance() }}
            {{ productNowInfo[activeIndex].Name }}
          </div>
          <div class="select-box">
            <!-- <span
              v-for="(item, index) in selectArr"
              :key="`c${index}`"
              @click="handleRage(index)"
              :class="{ activeItem: activeItem === index }"
            >
              {{ item }}%
            </span> -->
            <van-slider v-model="selectPrecent" @change="precentChange" :step="25" bar-height="7px" >
               <template #button>
                 <div class="custom-button">{{ selectPrecent }}%</div>
              </template>
            </van-slider>
          </div>
          <div class="enter-value">
            {{ $t('trade').jiaoyie }}：{{ allAmount }} USDT
          </div>
          <div
            class="btn"
            @click="submit"
            :style="{
              background:
                mode === 0 ? basicConfig.RiseColour : basicConfig.FallColour
            }"
          >
            {{ mode === 0 ? $t('trade').Buy : $t('trade').Sell }}
            {{ showProductName() }}
          </div>
        </div>
      </div>
      <div class="data">
        <div class="title">
          <span> {{ $t('trade').jiage }}</span>
          <span> {{ $t('trade').shuliang }}</span>
        </div>
        <div class="item top" v-for="(item, iIndex) in topData" :key="iIndex">
          <span :style="{ color: getColor(1) }">{{ item.price }}</span>
          <span>{{ item.num }}</span>
        </div>
        <div
          class="price"
          :style="{
            color: mode === 1 ? basicConfig.FallColour : basicConfig.RiseColour
          }"
        >
          {{ productNowInfo[activeIndex].Price }}
        </div>
        <div
          class="item bottom"
          v-for="(item, bIndex) in bottomData"
          :key="`b${bIndex}`"
        >
          <span :style="{ color: getColor(2) }">{{ item.price }}</span>
          <span>{{ item.num }}</span>
        </div>
        <div class="imgs">
          <div
            class="img"
            :class="{ activeImg: activeImg === imgIndex }"
            v-for="(item, imgIndex) in imgs"
            :key="`a${imgIndex}`"
            @click="activeImg = imgIndex"
          >
            <img :src="item" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Trust :productId="productNowInfo[activeIndex].Id" ref="trust" />
    <TabBar :active="active" />
    <!-- <SelectBox
      :actions="actions"
      :activeSelect="activeSelect"
      v-if="showActions"
      @close="showActions = false"
      @select="handleSelect"
    /> -->

    <van-popup class="popup" v-model="showActions" position="bottom">
      <van-picker
        show-toolbar
        :columns="actions"
        @confirm="handleSelect"
        @cancel="showActions = false"
        :confirm-button-text="$t('public').sure"
        :cancel-button-text="$t('public').cancel"
      />
    </van-popup>

    <van-popup
      v-model="show"
      position="left"
      :style="{ width: '244px', height: '100vh' }"
    >
      <PopupInfo :productNowInfo="productNowInfo" @change="changeProduct" />
    </van-popup>
  </div>
</template>

<script>
import PopupInfo from './components/popupInfo'
// import SelectBox from '@/components/selectBox'
import Trust from './components/trust'
import TabBar from '@/components/tabBarNew'
import productApi from '@/api/product'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
const nodata = [
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  }
]
export default {
  components: {
    TabBar,
    PopupInfo,
    // SelectBox,
    Trust
  },
  data() {
    return {
      active: 'Trade',
      mode: 0,
      value: null,
      activeItem: null,
      activeImg: null,
      show: false,
      productList: [],
      activeIndex: 0,
      form: {
        ProductId: null,
        OrderType: null,
        BuyDirection: null,
        Num: null,
        Price: null
      },
      activeSelect: 0,
      productNowInfo: [
        {
          BasicPrice: 0
        }
      ],
      DepthData: {},
      showActions: false,
      selectArr: [25, 50, 70, 100],
      selectPrecent: 25,
      actions: [this.$t('trade').xj, this.$t('trade').sj],
      LegalAmountInfo: [],
      CoinAmountInfo: [],
      topData: nodata,
      imgs: [
        require('@/assets/new/pan1.svg'),
        require('@/assets/new/pan2.svg'),
        require('@/assets/new/pan3.svg')
      ],
      bottomData: nodata
    }
  },
  watch: {
    mode() {
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      this.form.Num = null
    },
    activeImg(val) {
      if (this.DepthData.bids && this.DepthData.bids.length > 0) {
        switch (val) {
          case 0:
            this.topData = []
            for (let i = 0; i < 5; i++) {
              this.topData.push(this.DepthData.asks[i])
            }
            this.bottomData = []
            for (let j = 0; j < 5; j++) {
              this.bottomData.push(this.DepthData.bids[j])
            }

            break
          case 1:
            this.bottomData = this.DepthData.asks
            this.topData = []
            break
          case 2:
            this.topData = this.DepthData.bids
            this.bottomData = []
        }
      }
    }
  },
  computed: {
    ...mapGetters(['connection', 'basicConfig', 'lang']),
    allAmount() {
      if (this.activeSelect === 1) {
        const num = String(
          this.productNowInfo[this.activeIndex].Price * this.form.Num
        )
        if (num.indexOf('.') !== -1) {
          const arr = num.split('.')
          if (arr[1] && arr[1].length > 6) {
            return `${arr[0]}.${arr[1].substr(0, 6)}`
          } else {
            return num
          }
        } else {
          return num
        }
      } else {
        const { Price, Num } = { ...this.form }
        if (Price && Num) {
          const num = String(Price * Num)
          if (num.indexOf('.') !== -1) {
            const arr = num.split('.')
            if (arr[1] && arr[1].length > 6) {
              return `${arr[0]}.${arr[1].substr(0, 6)}`
            }
          }
          return num
        } else {
          return '0'
        }
      }
    }
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.off(this.productNowInfo[this.activeIndex].Name)
      this.connection.invoke('SendMsg', '')
    }
  },
  mounted() {
    this.firstEnter = false
    if (this.$route.query.mode) {
      this.mode = 1
    }
    this.getList()
    this.getUserInfo()
  },
  methods: {
    goProduct() {
      sessionStorage.setItem(
        'product',
        this.productNowInfo[this.activeIndex].Id
      )
      this.$router.push({
        name: 'Product'
      })
    },
    goContract() {
      sessionStorage.setItem(
        'leverage',
        this.productNowInfo[this.activeIndex].Id
      )
      this.$router.push({
        name: 'Leverage'
      })
    },
    getCoinBalance() {
      let Balance = ''
      this.CoinAmountInfo.forEach((item) => {
        if (item.ProductId === this.productNowInfo[this.activeIndex].Id) {
          return (Balance = item.Balance)
        }
      })
      return Balance
    },
    async submit() {
      if (!this.form.Price) {
        this.$toast(this.$t('trade').tips)
        return
      }
      if (!this.form.Num) {
        this.$toast(this.$t('trade').tips1)
        return
      }
      this.form.OrderType = this.activeSelect ? 'Present' : 'Limit'
      this.form.BuyDirection = this.mode ? 'Sell' : 'Buy'
      this.form.ProductId = this.productNowInfo[this.activeIndex].Id
      this.form.Price = Number(this.form.Price)
      this.form.Num = Number(this.form.Num)
      await productApi.createCoinOrder(this.form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.getUserInfo()
        this.$refs.trust.reset()
      }, 500)
    },
    handleRage(index) {
      this.activeItem = index
      if (this.mode) {
        this.form.Num = (this.selectArr[index] / 100) * this.getCoinBalance()
      } else {
        this.form.Num = parseInt(
          ((this.selectArr[index] / 100) * this.LegalAmountInfo.USDT) /
            this.productNowInfo[this.activeIndex].Price
        )
      }
    },
    precentChange() {
      if (this.mode) {
        this.form.Num = (this.selectPrecent / 100) * this.getCoinBalance()
      } else {
        this.form.Num = (
          ((this.selectPrecent / 100) * this.LegalAmountInfo.USDT) /
            this.productNowInfo[this.activeIndex].Price
        ).toFixed(3)
      }
    },
    async getUserInfo() {
      const res = await userApi.userInfo()
      this.LegalAmountInfo = res.LegalAmountInfo
      this.CoinAmountInfo = res.CoinAmountInfo
    },
    handleDel() {
      if (this.form.Price && this.form.Price > 1) {
        this.form.Price--
      } else {
        this.form.Price = 0
      }
    },
    handleAdd() {
      if (this.form.Price || this.form.Price === 0) {
        this.form.Price++
      } else {
        this.form.Price = 0
      }
    },
    getRole() {
      const Role = (
        ((this.productNowInfo[this.activeIndex].Price -
          this.productNowInfo[this.activeIndex].BasicPrice) /
          this.productNowInfo[this.activeIndex].Price) *
        100
      ).toFixed(2)
      return Role > 0 ? `+${Role}` : Role
    },
    getColor(index) {
      if (index === 1) {
        if (this.activeImg === 1) {
          return this.basicConfig.RiseColour
        } else {
          return this.basicConfig.FallColour
        }
      } else {
        if (this.activeImg === 2) {
          return this.basicConfig.FallColour
        } else {
          return this.basicConfig.RiseColour
        }
      }
    },

    handleSelect(index, value) {
      this.showActions = false
      this.activeSelect = value
    },
    firstSetTabProduct() {
      if (this.firstEnter) {
        return
      }
      this.productList.forEach((item, index) => {
        if (item.Id === this.ProductId) {
          this.firstEnter = true
          this.activeIndex = index
          return
        }
      })
      this.form.Price = this.productNowInfo[this.activeIndex].Price
    },
    async getList() {
      this.productList = await productApi.CoinProducts()
      const ProductId = sessionStorage.getItem('coin')
      this.ProductId = ProductId ? Number(ProductId) : this.productList[0].Id
      this.productNowInfo = this.productList

      this.firstSetTabProduct()
      this.signalRStart()
    },
    // 选择产品
    changeProduct(index) {
      this.show = false
      this.connection.off(this.productNowInfo[this.activeIndex].Name)
      this.activeIndex = index
      this.ProductId = this.productList[index].Id
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      sessionStorage.setItem('coin', this.ProductId)
      this.connection.invoke(
        'SendMsg',
        this.productNowInfo[this.activeIndex].Name
      )
      this.topData = nodata
      this.bottomData = nodata
      setTimeout(() => {
        this.$refs.trust.reset()
      }, 10)
    },
    async getSecondData(data) {
      let productNowInfo = data
      let productList = [...this.productList]
      productNowInfo.forEach((item) => {
        productList.some((pItem) => {
          if (pItem.Id === item.ProductId) {
            pItem.Price = item.Price
            return true
          }
        })
      })

      this.productNowInfo = productList
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke(
            'SendMsg',
            this.productNowInfo[this.activeIndex].Name
          )
        }
      }, 100)
      connection.on('secondPrices', function(res) {
        that.getSecondData(res)
      })
      connection.on('Depth', function(res) {
        that.getDepthData(res)
      })
    },
    getDepthData(res) {
      let that = this
      const res1 = JSON.parse(res)
      // console.log(that.productNowInfo[this.activeIndex].Name)
      // console.log(res1.Name)
      if (res1.Name === that.productNowInfo[this.activeIndex].Name) {
        const arr = res1['bids']
        const arr1 = res1['asks']
        let bids = []
        let asks = []
        arr.forEach((iitem) => {
          bids.push({
            price: iitem[0],
            num: iitem[1]
          })
        })
        arr1.forEach((iitem) => {
          asks.push({
            price: iitem[0],
            num: iitem[1]
          })
        })
        this.DepthData.bids = bids
        this.DepthData.asks = asks
        switch (this.activeImg) {
          case 1:
            this.bottomData = bids
            this.topData = []
            break
          case 2:
            this.topData = asks
            this.bottomData = []
            break
          default:
            this.topData = []
            for (let i = 0; i < 5; i++) {
              this.topData.push(this.DepthData.asks[i])
            }
            this.bottomData = []
            for (let j = 0; j < 5; j++) {
              this.bottomData.push(this.DepthData.bids[j])
            }
        }
        return
      }
    },
    showProductName() {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === this.ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 50px 0 50px;
  color: #7286a5;
  .popup {
    ::v-deep .van-picker {
      background: #020406;
      .van-picker__toolbar {
        background: #010203;
      }
    }
    ::v-deep .van-picker-column__item {
      color: #fff !important;
    }
    ::v-deep .van-picker-column__item--selected {
      font-size: 20px;
      font-weight: bold;
    }
    ::v-deep .van-picker__mask {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  header {
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    color: #fff;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
    z-index: 99;
    background: #131f30;
    img {
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }
    .right {
      img {
        margin-right: 15px;
        margin-left: 0;
      }
    }
    span {
      font-size: 18px;
      font-weight: bold;
    }
    .left {
      display: flex;
      align-items: center;
      .range {
        display: inline-block;
        padding: 1px;
        margin-left: 10px;
        font-size: 12px;
        background: rgba(250, 82, 82, 0.1);
      }
    }
  }
  .top {
    background: #17181e;
    display: flex;
    .order-box {
      padding: 0 14px 15px;
      width: 226px;
      .buy {
        display: flex;
        background: #1a273f;
        position: relative;
        .line {
          width: 15px;
          height: 55px;
          transform: rotate(25deg);
          position: absolute;
          left: calc(50% - 9px);
          top: -10px;
          background: #17181e;
        }
        .btn {
          flex: 1;
          height: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
        }
        .Up {
          color: #fff;
        }
        .Down {
          color: #fff;
        }
      }
      .buy-mode {
        padding: 14px 0 0;
        display: flex;
        align-items: center;
        font-size: 13px;
        img {
          width: 9px;
          height: 9px;
          margin-left: 5px;
        }
      }
      .box {
        .input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 38px;
          width: 100%;
          border: 1px solid #42526a;
          margin-top: 15px;
          input {
            padding: 0 10px;
            width: 91px;
            height: 100%;
            background: transparent;
            border: none;
            color: #fff;
          }
          span {
            font-size: 16px;
            color: #7286a5;
            margin-right: 10px;
          }
          .control {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // border-left: 1px solid #42526a;
            &::before {
              content: '';
              width: 1px;
              height: 36px;
              position: absolute;
              left: 0;
              background: #42526a;
            }
            img {
              width: 11px;
              height: 11px;
              padding: 0 10px;
            }
            .del {
              border-right: 1px solid #42526a;
            }
          }
        }
        .mark {
          padding-left: 10px;
          color: #fff;
          background: #000000;
        }
        .validate {
          margin: 10px 0;
        }
        .select-box {
          display: flex;
          margin: 20px 0;
          span {
            width: 40px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            border: 1px solid #42526a;
            &:nth-of-type(1) {
              margin-left: 0;
            }
          }
        }
        .btn {
          width: 195px;
          height: 40px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          color: #fff;
        }
        .activeItem {
          color: #fff;
          background: #4f7bb1;
        }
      }
    }
    .data {
      flex: 1;
      padding-right: 15px;
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .item {
        height: 26px;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .price {
        font-size: 18px;
      }
      .imgs {
        display: flex;
        .img {
          border: 1px solid #3a4163;
          padding: 1px;
          margin-right: 5px;
          img {
            width: 15px;
            height: 15px;
          }
        }
        .activeImg {
          border-color: #357ce1;
        }
      }
    }
  }
}
 .custom-button {
    width: 30px;
    color: #fff;
    font-size: 10px;
    line-height: 25px;
    text-align: center;
    background-color: #357ce1;
    border-radius: 100px;
  }
</style>
